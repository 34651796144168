<template>
    <v-card>

        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('add_details')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                    v-model="editedData.airportName"
                                    :label="$t('airport_name')"
                                          dense
                                          outlined
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                    v-model="editedData.IATACode"
                                    :label="$t('table.iata')"
                                    required
                                          dense
                                          outlined
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">

                            <v-text-field
                                    v-model="editedData.ICAOCode"
                                    :label="$t('table.icao')"
                                    required
                                          dense
                                          outlined
                            ></v-text-field>
                        </v-col>


                        <v-col cols="12" md="6">
                            <v-text-field
                                    v-model="editedData.latitude"
                                     :label="$t('latitude')"
                                    type="number"
                                    required
                                          dense
                                          outlined
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                    v-model="editedData.longitude"
                                    :label="$t('longitude')"
                                    type="number"
                                    required
                                          dense
                                          outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">

                            <v-text-field
                                    v-model="editedData.worldAreaCode"
                                    :label="$t('table.WAC')"
                                          dense
                                          outlined
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                md="6"
                        >
                            <v-autocomplete
                                    v-model="editedData.country"
                                    :items="countries"
                                    :label="$t('country')"
                                    clearable
                                            dense
                                          outlined
                                    single-line
                                    item-text="value"
                                    item-value="id"
                                    @change="getState"
                            ></v-autocomplete>
                        </v-col>

                        <!--<v-col
                                cols="12"
                                md="6"
                        >
                            <v-autocomplete
                                    v-model="editedData.state"
                                    :items="state"
                                    :label="$t('state')"
                                    clearable
                                    single-line
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>-->

                        <v-col cols="12" md="6">

                            <v-text-field
                                    v-model="editedData.airportCategory"
                                    :label="$t('airport_category')"
                                          dense
                                          outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">

                            <v-text-field
                                    v-model="editedData.airportType"
                                    :label="$t('table.airport_type')"
                                          dense
                                          outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">

                            <v-text-field
                                    v-model="editedData.operator"
                                    :label="$t('operator')"
                                          dense
                                          outlined
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                md="6"
                        >
                            <v-menu
                                    v-model="dateMenu"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="editedData.establishedYear"
                                            :label="$t('established_year')"
                                            clearable
                                            prepend-inner-icon="event"
                                                  dense
                                          outlined
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="editedData.establishedYear">

                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                    v-model="editedData.lengthOfRunway"
                                    :label="$t('table.length_of_runway')"
                                    type="number"
                                          dense
                                          outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">

                            <v-text-field
                                    v-model="editedData.numberOfBuilding"
                                    :label="$t('no_of_building')"
                                    type="number"
                                          dense
                                          outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    // import { required } from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "createAirportForm",
        props: ["success"],
        computed: {},
        watch: {
            updated: {
                handler: function (val) {
                    if (this.updated) {
                        this.updated = false;
                        this.$emit("formResponse", val);
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                countries: [],
                state: [],
                dateMenu: false,
                province: [],
                show: false,
                updated: false,
                editedData: {},
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {},
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async getState(val) {
                const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                this.state = stateList.data
            },

            async formData() {
                axios.get("Shared/CountryList").then(response => {
                    this.countries = response.data;
                }).catch(error => console.log(error.response))
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    this.editedData.latitude = parseInt(this.editedData.latitude)
                    this.editedData.longitude = parseInt(this.editedData.longitude)
                    this.editedData.lengthOfRunway = parseInt(this.editedData.lengthOfRunway)
                    this.editedData.numberOfBuilding = parseInt(this.editedData.numberOfBuilding)
                    axios.post("Airport/InsertIntoAirportDetailAsync", this.editedData).then(response => {
                        if (response.data.success) {
                            this.$v.$reset();
                            this.editedData = {}
                            this.servicesData.data = response.data
                            this.servicesData.message = "Details added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.VehicleType = "";
                this.editedData.VehicleCategory = "";
            }
        }
    };
</script>

<style scoped>
</style>